import React, { useState } from "react";
import GlobalContext from "./GlobalContext";

export const GlobalState = (props) => {
    const [selectedPlan, setSelectedPlan] = useState({});
    const [auth, setAuth] = useState({});
    const [authToken, setAuthToken] = useState('');
    const [order, setOrder] = useState({});
    const [couponPrice,setCouponPrice] = useState({'discount' : 0, price : 0, coupon :0});


    const setCheckoutPlan = (plan) => {
        setSelectedPlan(plan)
        setCouponPrice({'discount' : 0, price : plan.SubscriptionPriceWithTax, coupon :0})

        // eslint-disable-next-line
    }
    const setAuthorization = (data) => {
        setAuth(data)
        // eslint-disable-next-line
    }
    const setAuthorizationToken = (data) => {
        setAuthToken(data)
        // eslint-disable-next-line
    }
    const setOrderDetails = (data) => {
        setOrder(data)
        // eslint-disable-next-line
    }

    const setStateData = (data, type) => {
        if(type == 'coupon') {

            setCouponPrice(data)
        }
        // eslint-disable-next-line
    }
    return (
        <GlobalContext.Provider value={{selectedPlan, setCheckoutPlan, auth, setAuthorization, authToken, setAuthorizationToken, order, setOrderDetails, setStateData, couponPrice}}>
            {props.children}
        </GlobalContext.Provider>
    )
}