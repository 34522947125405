const SUBSCRIPTION_MID = "Watcho34402161958372";
const INITIATE_MID = "Watcho95980906162334";
const IS_STAGING = false;
const GTM_ID = 'GTM-52Z5WWW';
const LOCALSTORAGE_KEY = 'flexi-annual';
const RETURN_URL = 'https://otp.watcho.com/order-status/flexi-annual';
const TESTING_ENABLE = false;
const TESTING_MOBILE_NUMBERS = [];
const PAYTM_URL = "https://securegw.paytm.in";
export {SUBSCRIPTION_MID, INITIATE_MID, IS_STAGING, LOCALSTORAGE_KEY, GTM_ID, RETURN_URL, TESTING_ENABLE, TESTING_MOBILE_NUMBERS, PAYTM_URL}
