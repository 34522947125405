import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import './popups.css'
import OtpInput from 'react-otp-input';
import globalContext from '../../context/GlobalContext';
import { VaildPromoCode, encryptData, generateFlexiSubscriptionRequestAPI, generateSubscription, generateSubscriptionRequestAPI, generateTokenAPI, getActiveSubcriptionPlan, sendOtpRequest, validateOtp } from '../../api/Activity';
import axios from 'axios';
import { calculateDiscount, createSlug, getTotalOfAllOtt, getUserType, removeFromString } from '../../api/Functions';
import { billDeskSDK } from '../../api/Billdesk';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { buyPlanTag, pushGTMTAG } from '../../api/GTMTag';
import { INITIATE_MID, IS_STAGING, LOCALSTORAGE_KEY, RETURN_URL, SUBSCRIPTION_MID, TESTING_ENABLE, TESTING_MOBILE_NUMBERS } from '../../api/Variables';
const getCurrentDimension = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
}
const Subscription = ({ showCheckoutPopup, setShowCheckoutPopup, selectedPlan, selectPlanEvent }) => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [showForm, setIsShowForm] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [otpError, setIsOtpError] = useState(null);
    const [isCouponApplied, setIsCouponApplied] = useState(false);
    const [isCouponAppliedPopup, setIsCouponAppliedPopup] = useState(false);
    const [couponError, setCouponError] = useState(null);
    const [checkoutStep, setCheckoutStep] = useState(1);
    const [verificationData, setVerificationData] = useState({ mobile: null, otp: null, term_condition: false, auto_renew: true, term_condition: true });

    const [verificationError, setVerificationError] = useState({ mobile: null, otp: null, plan_error : null });
    const [isActivePlan, setIsActivePlan] = useState(false);
    const [checkoutMessage, setCheckoutMessage] = useState(1);

    const [isButtonDisabled, setIsButtonDisabled] = useState({ 'send_otp': false, 'verify_otp': false, 'make_payment': true });

    const screeRef = useRef(null)
    const checkout = useContext(globalContext)
    const [isResendOTP, setIsResendOTP] = useState(false);
    const [orderError, setOrderError] = useState(null);
    const [actualPlan, setActualPlan] = useState({});

    const [auth, setAuth] = useState({});






    const setFormData = (value, type) => {


        let updatedValue = {};
        if (type == 'mobile') {
            if (/\D/g.test(value)) {
                value = value.replace(/\D/g, '');
            }
            if (value.length <= 10) {

                updatedValue = { mobile: value };
                setVerificationData(verificationData => ({
                    ...verificationData,
                    ...updatedValue
                }));
            }
        }
        else if (type == 'otp') {
            if (/\D/g.test(value)) {
                value = value.replace(/\D/g, '');
            }
            updatedValue = { otp: value };

            if (value.length === 4) {
                setIsDisabled(false)

            } else {
                setIsDisabled(true);
            }

            setVerificationData(verificationData => ({
                ...verificationData,
                ...updatedValue
            }));

        }



    }
    const disableEnableButton = (data) => {
        setIsButtonDisabled(buttonDisabled => ({
            ...buttonDisabled,
            ...data
        }));
    }
    const getOtp = async (resent_otp = false) => {
        var error = { mobile: '', otp: '' }
        setVerificationData(verificationData => ({
            ...verificationData,
            ...{ 'otp': '' }
        }));
        setVerificationError(verificationData => ({
            ...verificationData,
            ...{ otp: '' }
        }));
        disableEnableButton({ send_otp: true })
        if (verificationData.mobile == null) {
            error = { mobile: 'Please enter mobile number' }
        }
        else if (verificationData.mobile.length != 10) {
            error = { mobile: 'Mobile number is invalid' }
        }
        else {
            if (IS_STAGING) {
                if (verificationData.mobile == 1234567890) {
                    setCheckoutStep(2)
                }
                else {
                    error.mobile = 'Mobile number is invalid';
                }
            }
            else {
                if(resent_otp == true) {
                    pushGTMTAG(checkout.selectedPlan, 'signin_resent_otp')

                }
                else {
                    
                    pushGTMTAG(checkout.selectedPlan, 'signin_initiated')
                }
              
                let param = await encryptData({ "MobileNo": verificationData.mobile, "Source": "NDLP" });
                const appData = await generateTokenAPI(param);
                if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'success') {
                    checkout.setAuthorizationToken(appData.Result)
                    const sendOtpAppData = await sendOtpRequest(param, appData.Result);
                    if (sendOtpAppData.ResultStatus && (sendOtpAppData.ResultStatus).toLowerCase() == 'success') {
                        error = { mobile: null }
                        setCheckoutStep(2)
                    }
                    else {
                        error = { mobile: sendOtpAppData.ResultMessage }
                    }
                }
                else {
                    error = { mobile: appData.ResultMessage }
                }
            }
        }
        setVerificationError(verificationData => ({
            ...verificationData,
            ...error
        }));
        disableEnableButton({ send_otp: false })
    }
    const verifyOtp = async () => {
        var error = {};
        setIsDisabled(true)
        var authentication = {};
        if (IS_STAGING) {
            if (verificationData.otp == 1234) {
                setCheckoutStep(3)
                disableEnableButton({ make_payment: false })
            }
            else {
                error = { otp: 'Please enter correct otp' }
            }
        }
        else {
            let param = await encryptData({ "MobileNo": verificationData.mobile, "OTP": verificationData.otp, "Source": "NDLP" });
            const appData = await validateOtp(param, checkout.authToken);
            if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'success') {
                checkout.setAuthorization(appData.Result)
                authentication = appData.Result;
                error = { otp: null }
                let param = await encryptData({ "OTTSMSID": appData.Result.OTTSMSID, "MobileNo": verificationData.mobile, "Source": "NDLP" });
                pushGTMTAG(checkout.selectedPlan, 'signin_completed', checkout.auth)
                const check_plan = await getActiveSubcriptionPlan(param, checkout.authToken);
                if (check_plan.ResultStatus && (check_plan.ResultStatus).toLowerCase() == 'success') {
                    if (appData.Result.CustomerCategory == 3) {
                        if ((check_plan.Result.ActivePlans).length > 0) {
                            setIsActivePlan(true)
                            setCheckoutMessage(3)
                            disableEnableButton({ make_payment: true });
                            authentication.make_payment = true;

                        }
                        else {
                            setIsActivePlan(false)
                            setCheckoutMessage(1)
                            disableEnableButton({ make_payment: false })
                            // subscribeFlexiPlan(selectedPlan, authentication);
                            authentication.make_payment = false;


                        }
                    }
                    else {
                        if ((check_plan.Result.ActivePlans).length > 0) {
                            setIsActivePlan(true)
                            setCheckoutMessage(3)
                            disableEnableButton({ make_payment: true });
                            authentication.make_payment = true;


                        }
                        else {
                            setIsActivePlan(false)
                            setCheckoutMessage(2)
                            disableEnableButton({ make_payment: false })
                            // subscribeFlexiPlan(selectedPlan, authentication);
                            authentication.make_payment = false;


                        }
                    }
                    if (TESTING_ENABLE && TESTING_MOBILE_NUMBERS.includes(verificationData.mobile)) {

                        setIsActivePlan(false)
                        setCheckoutMessage(1)
                        disableEnableButton({ make_payment: false });
                        authentication.make_payment = false;


                    }
                }
                setCheckoutStep(3)
                    authentication.checkoutMessage = checkoutMessage;
                    subscribeFlexiPlan(selectedPlan, authentication);
                    checkout.setCheckoutPlan(selectedPlan, authentication)
            }
            else {
                error = { otp: appData.ResultMessage }
            }
        }
        setIsDisabled(false)
        setVerificationError(verificationData => ({
            ...verificationData,
            ...error
        }));
    }



    const autoRenew = (e) => {
        var updatedValue = { auto_renew: false }
        if (e.target.checked) {
            updatedValue = { auto_renew: true }
        }
        setVerificationData(verificationData => ({
            ...verificationData,
            ...updatedValue
        }));
    }

    const resentOTPAction = () => {
        getOtp(true);
        setIsResendOTP(true);
        const getData = setTimeout(() => {
            setIsResendOTP(false)
        }, 3000);
    }
    const termCondition = (e) => {
        var updatedValue = { term_condition: false }
        if (e.target.checked) {
            updatedValue = { term_condition: true }
        }
        setVerificationData(verificationData => ({
            ...verificationData,
            ...updatedValue
        }));
    }



    const subscribeFlexiPlan = async (selectedPlan, authentication) => {
      
        var error = {};
        setIsDisabled(true)
        if (!authentication.make_payment) {

            authentication.auto_renew = verificationData.auto_renew;

            var addons_data = [];
            (selectedPlan.addonplans).map((item) => {
                addons_data.push({
                    "SubscriptionPlanID": item.PackageID,
                    "SubscriptionPlanType": "",
                    "SubscriptionPlanPackType": "A"
                });
            });
            addons_data.push({
                "SubscriptionPlanID": selectedPlan.SubscriptionPlanID,
                "SubscriptionPlanType": "FLEXI",
                "SubscriptionPlanPackType": "CP"
            })
            var data = {
                "MobileNo": authentication.MobileNo,
                "OTTSMSID": authentication.OTTSMSID,
                "Source": "NDLP",
                "FelxiSubsPlanRequests": addons_data
            };
            let param = await encryptData(data);
            const appData = await generateFlexiSubscriptionRequestAPI(param, checkout.authToken);
            if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'success') {
                checkout.setCheckoutPlan(appData)
                var result = appData.Result;
                result.plans = selectedPlan.SubscriptionApps;
                result.addonplans = selectedPlan.addonplans;
                navigate(`/planoffer/${createSlug((removeFromString(['WATCHO'], selectedPlan.SubscriptionPlanName)))}`, {

                    state: {
                        plan: result,
                        checkoutStep: checkoutStep,
                        authentication: authentication
                    }
                });
            }
            else {
                error = { plan_error: appData.ResultMessage }
            }
        }
        setIsDisabled(false)
        setVerificationError(verificationData => ({
            ...verificationData,
            ...error
        }));
    }


    return (
        <>
            <div className={showCheckoutPopup ? 'popupWrap show popupWrap-modal newpopup' : 'popupWrap popupWrap-modal'}>

                <div className='popupbody'>
                    <button className='closePop' onClick={() => selectPlanEvent(false, {})}><img src={require('../../assets/images/close.png')} alt='close' /></button>


                    <div className='poprightSec'>
                        <div className='subscriptionForm'>
                            <h2>Smart Choice!</h2>
                            <p>The World Of Watcho Shall Be Yours Shortly</p>
                            <div className='paysteps'>
                                <ul className='step-two'>
                                    <li className='previous-step'>
                                        <span className='dots fill'></span>
                                        <div>Add your<br /> favourite OTT's</div>
                                    </li>
                                    <li className='previous-step'>
                                        <span className='dots fill'></span>
                                        <div>Select<br /> a plan</div>
                                    </li>

                                    <li className={checkoutStep == 2 || checkoutStep == 1 ? 'active-step' : 'previous-step'}>
                                        <span className={`dots ${checkoutStep == 1 || checkoutStep == 2 ? 'next-step' : 'fill'}`}></span>
                                        <div>Verify your<br />  number</div>
                                    </li>
                                    <li className={checkoutStep == 3 || checkoutStep == 4 ? 'active-step' : 'next-stepper'}>
                                        <span className={`dots ${checkoutStep == 3 ? 'next-step' : (checkoutStep == 4 ? "fill" : 'empty')}`}></span>
                                        <div>Make<br />  payment</div>
                                    </li>
                                </ul>
                            </div>


                            <form className={checkoutStep == 1 ? 'watchform ' : 'watchform stepOne'}>
                                <h3 className='form-subheading'>
                                    Enter your mobile number to receive OTP
                                </h3>
                                <div className='fieldsWrap'>
                                    <fieldset>
                                        <legend>Country Code</legend>
                                        <div className='drop-list'>
                                            <img src={require('../../assets/images/flags/flag-in.png')} alt='IN' />
                                            <span>+91</span>
                                            {/* <i className='fa fa-angle-down'></i> */}
                                        </div>
                                    </fieldset>
                                    <fieldset >
                                        <legend>Mobile Number</legend>
                                        <input type='text' placeholder='Enter Mobile Number' className='input-field' value={verificationData.mobile} onChange={e => { setFormData(e.target.value, 'mobile') }} />

                                        {verificationError.mobile !== null ? <div className='errormsg'>{verificationError.mobile}</div> : null}
                                    </fieldset>
                                </div>
                                <div className='form-check'>
                                    <input type='checkbox' className='form-check-input' value='' id='terms' checked={verificationData.term_condition} onChange={(e) => { termCondition(e) }} />
                                    <label >I am over 18, and I agree to the above conditions and the<br />
                                        <Link to='/term-use'>Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link></label>
                                </div>
                                <div className='form-check'>
                                    <input type='checkbox' className='form-check-input form-check-input-disabled' disabled checked={verificationData.auto_renew} value='' id='renewsubs'  />

                                    {/* <input type='checkbox' className='form-check-input' checked={verificationData.auto_renew} value='' id='renewsubs' onChange={(e) => { autoRenew(e) }} /> */}
                                    <label htmlFor='renewsubs'>Auto Renew Your Subscription</label>
                                </div>

                                <button type='button' className='site-btn-default form-btn' disabled={isButtonDisabled.send_otp} onClick={getOtp}>Get OTP</button>
                            </form>

                            <form className={checkoutStep == 2 ? 'watchform stepTwo' : 'watchform stepTwo hide'}>

                                <h3 className='form-subheading'>
                                    Verify the OTP
                                </h3>
                                <span className='subtext'>The code has been sent to you on your mobile number (+91) {verificationData.mobile}</span>
                                <div className='otpContainerWrap'>
                                    <OtpInput

                                        value={verificationData.otp}
                                        onChange={e => { setFormData(e, 'otp') }}

                                        inputStyle={(otpError === null) ? 'otpbox' : otpError + ' otpbox'}
                                        containerStyle='otpContainer'
                                        numInputs={4}
                                        onKey
                                        // renderSeparator={<span>-</span>
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    {verificationError.otp !== null ? (<div className='errormsg'>
                                        {verificationError.otp}
                                    </div>) : null}

                                </div>
                                <div className='mb-30'>
                                    <span className='subtext'>Haven't received the verification code? <button className='inline-btn' type='button' onClick={resentOTPAction}>Resend OTP</button> or <button className='inline-btn' type='button' onClick={() => setCheckoutStep(1)}>Re-Enter Mobile Number</button></span>
                                  {isResendOTP ? <><br /><span>Otp is successfully sent</span></> : ''}
                                </div>
                                <button disabled={isDisabled ? true : false} type='button' className='site-btn-default form-btn' onClick={verifyOtp}>Verify OTP</button>
                            </form>
                            <form className={checkoutStep == 3 || checkoutStep == 4 ? 'watchform stepThree' : 'watchform stepThree hide'}>
                                <h3 className='form-subheading'>
                                    {/* Number Verified */}
                                    {/* <span className='success-check mobile-success-check'>
                                            <i className='fa fa-check'></i>
                                        </span> */}
                                </h3>
                                <span className='subtext verify-mobile'> <span className='block_sec'>  Your mobile number </span> <span className='subtext-verified' >
                                    (+91) {verificationData.mobile} is verified</span>
                                    <span className='success-check desktop-success-check1'>
                                        <i className='fa fa-check'></i>
                                    </span>
                                </span>

                                <div className='otpContainerWrap'>


                                    <div className=''>

                                    </div>

                                </div>
                                <div className='mb-30'>
                                    {
                                        checkoutMessage == 3 ?
                                            <>
                                                <span className='subtext white hello-again'>Hello there!<br />  You are all set, start binging on your favorites now! For any changes in the plan click the <a className='redirect-url' href='https://www.watcho.com/'>watcho.com</a> and login. If you wish to start a new connection, simply try with a different number, Happy Streaming! </span>
                                                <div className='payment-note'>*{`For Annual and < Rs.99 packs are not applicable for BOGO`}</div>

                                            </>
                                            :


                                            checkoutMessage == 2 ?
                                                <>

                                                    <span className='subtext white hello-again'>Hello Again!<br /> It’s good to have you back! You have already availed the complimentary offer on this number. Please complete your payment before you get started. Happy Streaming!</span>
                                                    <div className='payment-note'>*{`For Annual and < Rs.99 packs are not applicable for BOGO`}</div>
                                                </>

                                                :
                                                <>
                                                    <span className='subtext white hello-again'>Welcome to Watcho!<br />Complete your payment before you start streaming! Also, did we mention that you are eligible for the Watcho 1-month complimentary offer? Happy Streaming!</span>

                                                </>


                                    }
 {verificationError.otp !== null ? (<div className='errormsg'>
                                        {verificationError.plan_error}
                                    </div>) : null}
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Subscription
