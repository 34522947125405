import React, { useState } from 'react'
import Modal from 'react-modal'
import { getTotalOfAllOtt } from '../../api/Functions';

Modal.setAppElement('#root');
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '31.77083vw',
        borderRadius: '1vw'
    },
};

function OttPlatformModal({isOttPlatformModel, setIsOttPlatformModel, openCloseModal, popupPlan, planAddonList, ottPrice}) {
  
  
  return (
    <Modal
    isOpen={isOttPlatformModel}
    onRequestClose={() => openCloseModal(false)}
    style={customStyles}
    contentLabel="Plan Platform"
  >
    <div className='modal-content ott-platform-modal'>
      <button onClick={() => openCloseModal(false)} className='btn-close'><img src={require('../../assets/images/close.png')} alt='close' /></button>
      <h2 className='modal-heading'>Individual OTTs Market Price</h2>

      {
        popupPlan.SubscriptionApps ?
          <>

            <ul className='ott-list'>
              {
                popupPlan.SubscriptionApps.map((row) => {
                  return (
                    <li key={row.AppName}>
                      <div className='platformname'>{row.AppName}</div>
                      <img src={row.AppIconUrl} alt={row.AppName} />
                      <div className='platformprice'>&#8377; {row.AppPrice}</div>
                      <div className='platformTerm'>per {(popupPlan.SubscriptionPlanDurationName)?.toLowerCase() == 'annual' ? 'year' : 'month'}</div>
                    </li>
                  )
                })
              }

{
               planAddonList?.map((row) => {
                  return (
                    <li key={row.SubscriptionApp[0]?.AppName}>
                      <div className='platformname'>{row.SubscriptionApp[0]?.AppName}</div>
                      <img src={row.SubscriptionApp[0]?.AppIconUrl} alt={row.SubscriptionApp[0]?.AppName} />
                      <div className='platformprice'>&#8377; {row.SubscriptionApp[0]?.AppPrice}</div>
                      <div className='platformTerm'>per {(popupPlan.SubscriptionPlanDurationName)?.toLowerCase() == 'annual' ? 'year' : 'month'}</div>
                    </li>
                  )
                })
              }

            </ul>
          </>
          : ""
      }
      <div className='modal-footer'>
        <div className='totalCast'>Total Cost &#8377;   {ottPrice}</div>
        {/* <div className='saveUpto'>Save upto &#8377;  {ottPrice - popupPlan.SubscriptionPriceWithTax}</div> */}
      </div>
    </div>
  </Modal>
  )
}

export default OttPlatformModal