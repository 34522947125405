export const questionsAnswers = [
    {
      id : 1,
      question: "Can I edit Apps in the base pack?",
      answer:
        `<p>Apps in the base pack can not be changed or removed.</p>`,
    },
    {
      id : 2,
      question: "What are the benefits of Gold membership?",
      answer:`<p>Gold membership provides unparalleled benefits that cater to changing OTT subscription needs. A subscriber in any Flexi plan can place an advance request for replacing 1 App from the next billing cycle, within existing subscription validity without any charges.</p>
      <p>Gold membership will cost the user Rs.199/annum which is available FREE as part of the launch offer. More features will be added in the future and will be communicated from time to time.</p>`,
    },
    {
      id : 3,
      question: "What is a calendar feature?",
      answer:      `<p>Calendar is the cool feature of Gold membership where in you can plan for the future. Once you have subscribed to any Flexi pack, the Calendar feature becomes available on the top right corner of the subscribed plan. This allows you to place an advance request to change any 1 App for the next 30 days’ billing cycle with no extra payment/charges. A subscriber can choose to avail this facility anytime till the 29th day of the existing bill cycle.</p>`,
    },
    {
      id : 4,
      question: "I have added only 5 Apps in Flexi Max and have left 3 out of my choice. Will I still be charged Rs.299?",
      answer:    `<p>Yes, You can choose either all or a minimum of 5 Apps besides the base plan. The pack MRP charged will not change.</p>`,
    },
    {
      id : 5,
      question: "I want to add more than 5 Apps in the next month. Can I do that in the calendar facility? ",
      answer:    `<p>Absolutely, A subscriber of the Flexi Max pack can add Apps >5 which will be effective from the start of next billing cycle of 30 days without additional charges.
      </p>`,
    },
    {
      id : 6,
      question: "How do I change the pack from one to another?",
      answer:    `<p>If you want to change from 1 Flexi plan to another, you can change from a lower pack value to a higher pack value by paying the applicable subscription amount. Please note that in this case, the existing pack’s remaining validity, balance, and scheduled calendar will not be adjusted or carried forward.
      </p>`,
    } ,
    {
      id : 7,
      question: "I have subscribed to the Annual Flexi plan, Are features under Gold membership available for me too?",
      answer:    `<p>Yes, as an annual subscriber, you can plan the replacement of 1 App with any other 1 App of your choice from the options given, which will be effective from the next billing cycle of 30 days.
      </p>`,
    } ,
    {
      id : 8,
      question: "I am an existing Watcho Subscriber, can I take any Flexi plan? ",
      answer:    `<p>After canceling or expiry of your existing Watcho plan, you can subscribe to any Flexi plan of your choice.</p>`,
    } 
  ];